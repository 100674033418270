export const defaultValues = {
  PROPERTY_VALUE: 400000,
  DEPOSIT: 40000,
  DURATION: "30",
  INTEREST: "4",
  DEFAULT_RESULT: false,
  INSTANT_RESULT: false,
  CURRENCY: "€",
  PRICE_PREFIX: true,
  DURATION_SUFFIX: true,
  YEAR_SUFFIX: true,
  YEAR_SELECT: true,
}
